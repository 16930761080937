<template>
  <div class="position-relative"> 

  <b-row>
    <b-col md="4"
    >   
      <b-button
      v-on:click="buildSuppData"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      v-b-toggle.sidebar-addTransaction
      >
        Add Transaction
      </b-button>

      <b-dropdown
      id="dropdown-grouped-amount"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      dropright
      :text="dropdownText"
      class="ml-1"
      >

        <b-dropdown-group
          id="dropdown-group-month"
          header="By Month"
        >
          <b-dropdown-item @click="clearFilter">All</b-dropdown-item>
          <b-dropdown-item @click="showDateRange">Custom range</b-dropdown-item>
          <b-dropdown-item @click="getMonthAmount(month)" v-for="month in getAllMonthsList" :key="month">{{month}}</b-dropdown-item>

        </b-dropdown-group>
      </b-dropdown>

      <h5 v-if="showRangeDate" class="mt-2">Filtered Amount : <b>{{money_formattar(rangeTotal)}}</b></h5>
      <h5 v-else-if="month" class="mt-2">Filtered Amount : {{money_formattar(monthTotal)}}</h5>
      <h6 v-if="!showRangeDate && !month" class="mt-2">All Transactions Amount : {{money_formattar(allTotal)}}</h6>
        
    </b-col>

    <b-col md="4"
      >
      <span v-if="showRangeDate">
      <b-row>
        <b-col md="6">
        <b-form-group
            label="Start Date"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="md"
            label-for="startDate"
            >
          <flat-pickr
            v-model="dateFrom"
            class="form-control"
          />
        </b-form-group>
        </b-col>

          <b-col md="6">
          <b-form-group
            label="End Date"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="md"
            label-for="endDate"
            >
            <flat-pickr
              v-model="dateTo"
              class="form-control displayInline"
            />
          </b-form-group>
          </b-col>
      </b-row>
      </span>
    </b-col>

    <b-col md="4">
      <b-row>
        <b-col md="8">
          <b-form-group
               
              label-cols-sm="2"
              label-align-sm="right"
              label-size="md"
              label-for="filterInput"

            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter=''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
               
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageInput"
              >

              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                @change="savePerPage(perPage)"
              />
          </b-form-group>

        </b-col>
      
      </b-row>
    </b-col>
  </b-row>
    <!-- <pre>{{ selectedItems }}</pre> -->
    <!-- <span>Total Rows : <b>{{totalRows}}</b></span> -->
    <!-- <span v-if="month"><b>{{month}} Transactions</b></span>
    <span v-else><b>All Transactions</b></span> -->
    <b-table
  v-if="tableRows.length > 0"
  :items="tableRows"
  :fields="fields"
  striped
  responsive
  id="purchasing-table"
  :per-page="perPage"
  :current-page="currentPage"
  small
  class="hide-scroll-bar"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
        <span>Total Rows : <b>{{trans.length}}</b></span>
        
        <template #cell(name)="row">
            <div>
                <span>{{row.item.name}}</span><br>
                <small>{{row.item.created_at | formatDate}}</small>
            </div>       
        </template>

        <template #cell(amount)="row">
                {{money_formattar(row.item.amount)}}

        </template>
        
        <template #cell(type)="row">
                {{row.item.type}}
                <p v-if="row.item.supplier!='null'"><small>{{row.item.supplier}}</small></p>

        </template>

      </b-table>
      <div v-else class="text-center mt-4">{{ emptyTransactionMessage }}</div>
      <b-pagination
      :total-rows="totalRows"
      :per-page="perPage"
      v-model="currentPage"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
      >
          <template #prev-text>
              <feather-icon
              icon="ChevronLeftIcon"
              size="18"
              />
          </template>
          <template #next-text>
              <feather-icon
              icon="ChevronRightIcon"
              size="18"
              />
          </template>
      </b-pagination>

    <add-trans-side-bar
    :suppliers="mySuppdata"
    @refresh="refresh"
    />

    <b-overlay 
    :show="show"
    no-wrap
    spinner-variant="primary"
    ></b-overlay>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BPagination,
  BFormRadio, VBToggle ,BDropdown,VBTooltip,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay,
  BDropdownDivider,
  BDropdownGroup,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddTransSideBar from './AddTransSideBar.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownGroup,
    BDropdownItem,
    ToastificationContent,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BOverlay,
    AddTransSideBar,
    flatPickr
  },
  
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },

  data() {
    return {
        perPage: '25',
        pageOptions: [25,50,100,200],
        currentPage: 1,
        emptyTransactionMessage: "No Data Found.",
        fields: ['name','amount', 'type'],
        trans:[],
        totalRows:1,
        filter: null,
        filterOn: [],
        show:false,
        supp:[],
        mySuppdata:[],
        month:false,
        month_list : [{value:"Jan",text:"Jan"}, {value:"Feb",text:"Feb"}, {value:"Mar",text:"Mar"}, {value:"Apr",text:"Apr"}, 
        {value:"May",text:"May"}, {value:"Jun",text:"Jun"}, {value:"Jul",text:"Jul"},{value:"Aug",text:"Aug"},
        {value:"Sep",text:"Sep"}, {value:"Oct",text:"Oct"}, {value:"Nov",text:"Nov"},{value:"Dec",text:"Dec"}],
        rangeDate: null,
        dateFrom:null,
        dateTo:null,
        showRangeDate:false,
        monthTotal:0,
        dropdownText:'Filter By Date',
        rangeDates:[],
        filteredByMonth:[]
    }
  },
  mounted() {
    this.perPage=this.getPerPage() ? this.getPerPage() : '25'
    this.get()
  },
  computed:{
    allTotal(){
      var total = 0
      for(let i = 0; i < this.trans.length; i++) {
        total += parseFloat(this.trans[i].amount);
      }
      return total
    },
    tableRows(){
      if(this.dateTo && this.dateFrom){
        return this.rangeDates
      }
      else if(this.month){
        return this.filteredByMonth
      }
      else{
        return this.trans
      }
    },
    rangeTotal(){
      if(this.showRangeDate){
        this.filter=''
        this.month=false
        this.rangeDates=[]
        
        if(this.dateFrom && this.dateTo){
          this.dropdownText = this.dateFrom + ' to ' +this.dateTo
          var dates =[] 
          var filteredDates = []

          for (let index = 0; index < this.trans.length; index++) {   
            dates.push({data:this.trans[index],date:moment(String(this.trans[index].created_at)).format('YYYY-MM-DD'),
            amount:this.trans[index].amount})         
          }

          var startDate = moment(String(this.dateFrom)).format('YYYY-MM-DD')
          var endDate = moment(String(this.dateTo)).format('YYYY-MM-DD')
          
          for (let index = 0; index < dates.length; index++) {
            if(dates[index].date >= startDate && dates[index].date <= endDate){
              filteredDates.push(dates[index])
            }   
          }
          var total = 0
          for(let i = 0; i < filteredDates.length; i++) {
            total += parseFloat(filteredDates[i].amount);
          }

          for (let k = 0; k < filteredDates.length; k++) {
            this.rangeDates.push(filteredDates[k].data) 
          }
           
        return total
        }
      }
    },
    getAllMonthsList(){
    
      var monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
      var d = new Date();
      var monthsList = []
      d.setDate(1);
      for (let i=0; i<=11; i++) {
          monthsList.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
          d.setMonth(d.getMonth() - 1);
      }
      return monthsList
    },
    rows(){
      return this.trans.length
      },

  },
  created(){
    this.perPage=this.getPerPage() ? this.getPerPage() : '25'
    this.get()    
  },
  methods:{
    async get(){
      this.show = true
      await axios.get(process.env.VUE_APP_API+'/accounts')
      .then(response => {
        this.trans = response['data']
        this.totalRows = this.trans.length
      })
      .then(
          axios.get(process.env.VUE_APP_API+'/get-super-supp')
          .then(response=>{
              this.supp = response['data']
                this.show = false
                })     
      )
    },
    refresh(){
      this.get()
      this.selectedItems = []
    },
    buildSuppData(){
        let suppData=[]
        for(let i in this.supp){
            suppData.push({value:this.supp[i].name,text:this.supp[i].name})      
        }
        //suppData.push({value:null,text:'Please select a vendor'})
        this.mySuppdata = suppData
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showDateRange(){
      this.showRangeDate = true
      this.dropdownText='Enter Range'
    },
    getMonthAmount(m){
      this.filteredByMonth=[]
      this.dateFrom=null
      this.dateTo=null
      this.dropdownText = m
      this.month = true
      this.showRangeDate = false
      var splitDate = m.split(' ')
      var monthNumber = moment().month(splitDate[0]).format("MM")
      var year = moment().year(splitDate[1]).format("Y")
      
      var dates =[] 
      var filteredDates = []
    
      for (let index = 0; index < this.trans.length; index++) {   
        dates.push({data:this.trans[index],date:moment(String(this.trans[index].created_at)).format('YYYY-MM'),
        amount:this.trans[index].amount})         
      }
      
      for (let index = 0; index < dates.length; index++) {
        if(dates[index].date >= year+'-'+monthNumber && dates[index].date <= year+'-'+monthNumber){
          filteredDates.push(dates[index])
        }   
      }
      var total = 0
      for(let i = 0; i < filteredDates.length; i++) {
        total += parseFloat(filteredDates[i].amount);
      }

      for (let j = 0; j < filteredDates.length; j++) {
        this.filteredByMonth.push(filteredDates[j].data)     
      }
      
      return this.monthTotal = total
      //this.filter = year+'-'+monthNumber
    },

    clearFilter(){
      this.filteredByMonth=[]
      this.rangeDates=[]
      this.month=false
      this.rangeDate= null
      this.dateFrom=null
      this.dateTo=null
      this.showRangeDate=false
      this.dropdownText = 'Filter By Date'
    },
    money_formattar(amount){
      var formatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      });
      return formatter.format(amount)
    }
  },
  filters: {
    formatDate (value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD , hh:mm:ss')
      }
    }
  },
}
</script>

<style lang="scss">

@import '@core/scss/vue/libs/vue-flatpicker.scss';

.bg{
  border:2px solid black;
}
</style>